import axios from 'axios';
import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

export const generalConfig = {
  baseURL: process.env.REACT_APP_API + 'v1/admin-panel/nutrition-clubs/',
  headers: {
    Authorization: 'Bearer ' + getToken(),
  },
};
export const Export_Nutrition_Club_URL = 'exportv2?MemberType=2&download=true';

const getNCOs = (params, thunkAPI) => {
  return axiosClient.get('nutrition-clubs/members', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getNCODetail = ({ id, isUsingNutritionApi = false }, thunkAPI) => {
  return axiosClient.get(
    `${isUsingNutritionApi ? 'nutrition-clubs/member/' : 'members/'}` + id,
    {
      signal: thunkAPI?.signal,
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    },
  );
};

const postNCO = (data) => {
  return axiosClient.post('members', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getNOs = (params, thunkAPI) => {
  return axiosClient.get('nutrition-clubs', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putNCO = (data) => {
  return axiosClient.patch('members/' + data.id, data.values, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getType = (params, thunkAPI) => {
  return axiosClient.get('enums/nutrition-club-customer-levers', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const exportNCOs = (params, options) => {
  return axiosClient.get('nutrition-clubs/exportv2', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const resetPasswordNCO = (id) => {
  return axiosClient.patch(`members/dat-lai-mat-khau?memberId=${id}`, null, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const logoutNCO = (id) => {
  return axiosClient.patch(`members/dang-xuat-tai-khoan?memberId=${id}`, null, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
      'content-type': 'application/json;charset=UTF-8',
    },
  });
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API + 'v3/admin-panel/',
});

const logoutMainSubAccount = (data) => {
  return instance.post(`authentication/logout`, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
      'content-type': 'application/json;charset=UTF-8',
    },
  });
};

export {
  exportNCOs,
  getNCODetail,
  getNCOs,
  getNOs,
  getType,
  logoutMainSubAccount,
  logoutNCO,
  postNCO,
  putNCO,
  resetPasswordNCO,
};
